<template>
  <v-form ref="form">
    <v-stepper v-model="step" vertical class="elevation-0">
      <v-stepper-step
        step="1"
        :complete="stepImageCompleted"
        :color="stepImageCompleted ? 'success' : 'primary'"
        @click="step = 1"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 1">
          <div>Banner image</div>
        </div>
      </v-stepper-step>
      <v-stepper-content step="1">
        <div class="image-wrapper">
          <div class="edit-image-wrapper" v-if="value.image_url">
            <v-img
              class="bordered cursor-pointer"
              :src="value.image_url"
              aspect-ratio="1.7"
              width="736px"
              height="380px"
            ></v-img>
            <div
              class="edit-image-icon cursor-pointer"
              @click="showCampaignUploadDialog = true"
            >
              <v-icon color="white" large>fal fa-edit</v-icon>
            </div>
          </div>
          <div
            v-else
            class="bordered empty-image"
            @click="showCampaignUploadDialog = true"
          >
            <v-avatar color="primary" size="100">
              <span class="white--text headline">
                <v-icon color="white" large>fal fa-plus</v-icon>
              </span>
            </v-avatar>
          </div>
        </div>
        <campaign-image-upload-dialog
          v-model="showCampaignUploadDialog"
          :campaign="value"
          @save="step = 2"
        ></campaign-image-upload-dialog>
      </v-stepper-content>
      <v-stepper-step
        step="2"
        :complete="stepStartDateCompleted"
        :color="stepStartDateCompleted ? 'success' : 'primary'"
        @click="step = 2"
        class="cursor-pointer"
      >
        <div class="cursor-pointer" @click="step = 2">
          <div>Duration</div>
        </div>
      </v-stepper-step>
      <v-stepper-content step="2">
        <v-container grid-list-xl class="pl-0 pr-0">
          <v-flex column>
            <v-flex xs12 sm6>
              <v-text-field
                v-model="value.name"
                label="Campaign name"
                hint="Min. 10 characters"
                persistent-hint
                :counter="64"
                v-validate="'min:3|max:64'"
                data-vv-name="Campaign name"
                :error-messages="errors.collect('Campaign name')"
                data-vv-validate-on="blur"
                class="mb-3"
                data-intercom-target="create_campaign.input.name"
              >
                <template v-if="$vuetify.breakpoint.smAndUp" slot="append">
                  <emoji-picker
                    close-on-content-click
                    @emoji="value.name += $event"
                  ></emoji-picker>
                </template>
              </v-text-field>
            </v-flex>
            <v-flex xs12 sm6>
              <date-picker
                v-model="startingAt"
                label="Start date"
                :min-date="minDate"
                :max-date="maxDate"
                @change="startDateChange"
                :disabled="disableLengthChange"
                :dataVvValidateOn="!startDateCorrect"
                :error-messages="!startDateCorrect ? 'Invalid date' : ''"
                data-intercom-target="create_campaign.input.start_date"
              ></date-picker>
            </v-flex>
            <v-flex xs12 sm6>
              <v-select
                v-model="duration"
                :items="durationItems"
                label="Duration"
                @change="durationChange"
                :disabled="disableLengthChange"
                data-intercom-target="create_campaign.input.duration"
              ></v-select>
            </v-flex>
          </v-flex>
        </v-container>
      </v-stepper-content>
    </v-stepper>
    <div class="text-center pb-4">
      <v-btn class="mr-2" color="black" text @click="$emit('previous', true)"
        >Back</v-btn
      >
      <v-btn
        color="success"
        :disabled="!allCompleted || todayMoreThanStartDate"
        medium
        @click="$emit('publish', true)"
        data-intercom-target="create_campaign.btn.publish"
        >Publish</v-btn
      >
    </div>
  </v-form>
</template>
<script>
import { mapState } from "vuex";
import DatePicker from "@/components/common/filters/DatePicker";
import CampaignImageUploadDialog from "../../../dialogs/CampaignImageUploadDialog";
import EmojiPicker from "../../../pickers/EmojiPicker";

export default {
  components: { CampaignImageUploadDialog, DatePicker, EmojiPicker },
  props: {
    value: {
      required: true
    }
  },
  data: () => ({
    step: 1,
    startingAt: null,
    duration: 30,
    durationItems: [
      { value: 30, text: "30 Days" },
      { value: 60, text: "60 Days" },
      { value: 90, text: "90 Days" },
      { value: 180, text: "180 Days" },
    ],
    showCampaignUploadDialog: false,
    disableLengthChange: false,
    startDateCorrect: true
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    stepStartDateCompleted() {
      return Boolean(
        this.value.starting_at &&
          this.value.ending_at &&
          this.value.name &&
          this.value.name.length >= 10 &&
          this.startDateCorrect
      );
    },
    stepImageCompleted() {
      return !!this.value.image_url;
    },
    allCompleted() {
      return this.stepStartDateCompleted && this.stepImageCompleted;
    },
    todayMoreThanStartDate() {
      const start = this.$moment(this.value.starting_at);
      const today = this.$moment();
      const daysAfterStart = Math.ceil(
        today.diff(start) / (1000 * 60 * 60 * 24)
      );
      if (daysAfterStart > 1) {
        return true;
      } else {
        return false;
      }
    },
    minDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    maxDate() {
      return this.$moment()
        .add(12, "months")
        .format("YYYY-MM-DD");
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!newValue) {
          return;
        }
        if (!oldValue || newValue.id !== oldValue.id) {
          if (newValue.starting_at && newValue.ending_at) {
            this.calcChanges(newValue);
          }
        }
      }
    }
  },
  methods: {
    startDateChange(event) {
      this.value.starting_at = event;
      this.value.ending_at = this.$moment(event, "YYYY-MM-DD")
        .add(this.duration, "days")
        .format("YYYY-MM-DD");
      this.calcChanges(this.value);
    },
    durationChange(event) {
      this.value.ending_at = this.$moment(this.startingAt, "YYYY-MM-DD")
        .add(event, "days")
        .format("YYYY-MM-DD");
    },
    calcChanges(value) {
      // calc duration
      if (!value.starting_at) {
        return false;
      }
      this.startingAt = this.$moment(value.starting_at).format("YYYY-MM-DD");
      const today = this.$moment();
      const start = this.$moment(value.starting_at);
      const end = this.$moment(value.ending_at);
      const diff = end.diff(start);
      const todayDiff = start.diff(today);
      this.duration = Math.ceil(diff / (1000 * 60 * 60 * 24));
      const isOldDate = Math.ceil(todayDiff / (1000 * 60 * 60 * 24)) < 0;
      if (isOldDate) {
        this.startDateCorrect = false;
      } else {
        this.startDateCorrect = true;
      }
    }
  },
  created() {
    window.Intercom('trackEvent', 'create_campaign.step4');
  },
  mounted() {
    this.calcChanges(this.value);
  }
};
</script>
<style lang="scss" scoped>
.image-wrapper {
  max-width: 736px;
  .edit-image-wrapper {
    position: relative;
    .edit-image-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
    &:hover {
      .edit-image-icon {
        opacity: 1;
      }
    }
  }
  .empty-image {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 736px;
    height: 380px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fafafa;
    }
  }
}
</style>
