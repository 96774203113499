<template>
  <div>
    <v-dialog v-model="showDialog" width="100%" max-width="500px">
      <v-card>
        <v-card-title v-if="title || subtitle">
          <div>
            <div v-if="title" class="headline">{{ title }}</div>
            <div v-if="subtitle" class="subtitle-1 light-grey">
              {{ subtitle }}
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <template v-if="!!$slots.body">
            <slot name="body"></slot>
          </template>
          <template v-else>
            <div v-if="text" v-html="text"></div>

            <div v-if="video" :class="{ 'mt-4': !!text }">
              <youtube :url="video" style="min-height: 280px;"></youtube>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip top :disabled="!tooltip || $vuetify.breakpoint.smAndDown">
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          :x-large="iconSize === 'xlarge'"
          :large="iconSize === 'large'"
          :small="iconSize === 'small'"
          @click="showDialog = true"
        >
          {{ icon }}
        </v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import Youtube from "../misc/Youtube";
export default {
  components: { Youtube },
  props: {
    icon: {
      type: String,
      required: false,
      default: "fal fa-question-circle"
    },
    iconSize: {
      type: String,
      required: false,
      default: "small"
    },
    tooltip: {
      type: String,
      required: false,
      default: "See more"
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    subtitle: {
      type: String,
      required: false,
      default: ""
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    video: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    showDialog: false
  })
};
</script>
