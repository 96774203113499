<template>
  <v-stepper v-model="step">
    <v-stepper-header>
      <v-stepper-step
        step="1"
        :complete="completedSteps.one"
        :color="completedSteps.one ? 'success' : 'primary'"
        :data-intercom-target="'create_campaign.step1.completed.' + (completedSteps.one ? 'yes' : 'no')"
      >
        <div
          :class="{ 'cursor-pointer': completedSteps.one }"
          @click="completedSteps.one ? (step = 1) : null"
        >
          Settings
        </div>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        step="2"
        :complete="completedSteps.two"
        :color="completedSteps.two ? 'success' : 'primary'"
        :data-intercom-target="'create_campaign.step2.completed.' + (completedSteps.two ? 'yes' : 'no')"
      >
        <div
          :class="{
            'cursor-pointer': completedSteps.one || completedSteps.two
          }"
          @click="completedSteps.one || completedSteps.two ? (step = 2) : null"
        >
          Compensation
        </div>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        step="3"
        :complete="completedSteps.three"
        :color="completedSteps.three ? 'success' : 'primary'"
        :data-intercom-target="'create_campaign.step3.completed.' + (completedSteps.three ? 'yes' : 'no')"
      >
        <div
          :class="{
            'cursor-pointer': completedSteps.two || completedSteps.three
          }"
          @click="
            completedSteps.two || completedSteps.three ? (step = 3) : null
          "
        >
          Description
        </div>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        step="4"
        :complete="completedSteps.four"
        :color="completedSteps.four ? 'success' : 'primary'"
        :data-intercom-target="'create_campaign.step4.completed.' + (completedSteps.four ? 'yes' : 'no')"
      >
        <div
          :class="{
            'cursor-pointer': completedSteps.three || completedSteps.four
          }"
          @click="
            completedSteps.three || completedSteps.four ? (step = 4) : null
          "
        >
          Publish
        </div>
      </v-stepper-step>
    </v-stepper-header>
    <v-stepper-items>
      <v-stepper-content step="1">
        <step1 v-model="campaign" ref="stepOneRef" @next="step = 2"></step1>
      </v-stepper-content>
      <v-stepper-content step="2">
        <step2
          v-model="campaign"
          ref="stepTwoRef"
          @previous="step = 1"
          @next="step = 3"
        ></step2>
      </v-stepper-content>
      <v-stepper-content step="3">
        <step3
          v-model="campaign"
          ref="stepThreeRef"
          @previous="step = 2"
          @next="step = 4"
        ></step3>
      </v-stepper-content>
      <v-stepper-content step="4">
        <step4
          v-model="campaign"
          ref="stepFourRef"
          @previous="step = 3"
          @next="step = 5"
          @publish="$emit('publish', true)"
        ></step4>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4
  },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    step: 1,
    campaignId: null,
    completeInterval: null,
    completedSteps: {
      one: false,
      two: false,
      three: false,
      four: false
    }
  }),
  computed: {
    campaign: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      }
    }
  },
  methods: {
    startCompletedStatusInterval() {
      this.completedInterval = setInterval(() => {
        this.completedSteps.one = this.$refs.stepOneRef
          ? this.$refs.stepOneRef.allCompleted
          : false;
        this.completedSteps.two = this.$refs.stepTwoRef
          ? this.$refs.stepTwoRef.allCompleted
          : false;
        this.completedSteps.three = this.$refs.stepThreeRef
          ? this.$refs.stepThreeRef.allCompleted
          : false;
        this.completedSteps.four = this.$refs.stepFourRef
          ? this.$refs.stepFourRef.allCompleted
          : false;
        for (let property in this.completedSteps) {
          if (this.completedSteps[property] === false) {
            this.$emit("completed", false);
            return;
          }
        }
        this.$emit("completed", true);
      }, 1000);
    },
    stopCompletedStatusInterval() {
      clearInterval(this.completedInterval);
    }
  },
  created() {
    this.startCompletedStatusInterval();
  },
  destroyed() {
    this.stopCompletedStatusInterval();
  }
};
</script>
