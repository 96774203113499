<template>
  <iframe
    type="text/html"
    :src="
      'https://www.youtube-nocookie.com/embed/' +
        getVideoIdFromURL +
        videoParams
    "
    frameborder="0"
    width="100%"
    height="100%"
  ></iframe>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    getVideoIdFromURL() {
      let youtubeRegexp = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
      let id = this.url.replace(youtubeRegexp, "$1");

      if (id.includes(";")) {
        const pieces = id.split(";");

        if (pieces[1].includes("%")) {
          let uriComponent = decodeURIComponent(pieces[1]);
          id = `http://youtube.com${uriComponent}`.replace(youtubeRegexp, "$1");
        } else {
          id = pieces[0];
        }
      } else if (id.includes("#")) {
        id = id.split("#")[0];
      }

      return id;
    },
    getTimeFromURL() {
      let timeRegexp = /t=(\d+)[ms]?(\d+)?s?/;
      let times = this.url.match(timeRegexp);

      if (!times) {
        return 0;
      }

      const [full] = times;
      let [, minutes, seconds] = times;

      if (typeof seconds !== "undefined") {
        seconds = parseInt(seconds, 10);
        minutes = parseInt(minutes, 10);
      } else if (full.includes("m")) {
        minutes = parseInt(minutes, 10);
        seconds = 0;
      } else {
        seconds = parseInt(minutes, 10);
        minutes = 0;
      }

      return seconds + minutes * 60;
    },
    videoParams() {
      // https://developers.google.com/youtube/player_parameters
      return "?color=white&cc_load_policy=0&iv_load_policy=3&fs=0";
    }
  }
};
</script>
