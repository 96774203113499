<template>
  <v-dialog max-width="85%" v-model="showDialog">
    <v-card>
      <campaign-card :campaign="campaign" flat></campaign-card>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showDialog = false">Close</v-btn>
        <v-btn
          color="success"
          :loading="publishLoading"
          :disabled="!canPublish"
          medium
          @click="publishCampaign"
        >
          <span v-if="canPublish && !publishLoading">Publish</span>
          <span v-else-if="isCpcCampaign && ! hasCpcPermission">Requires different subscription</span>
          <span v-else-if="isCpaCampaign && ! hasCpaPermission">Requires pixel approval</span>
          <span v-else>Max {{ allowedActiveCampaigns }} active </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapState, mapActions } from "vuex";
import CampaignCard from "../cards/CampaignCard";

export default {
  components: { CampaignCard },
  props: {
    value: {
      required: true,
      type: Boolean,
      default: false
    },
    campaign: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      publishLoading: false,
      activeCampaigns: null
    };
  },
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", ["isAgency"]),
    ...mapGetters("subscriptions", ["getSetting", "hasPermission"]),
    showDialog: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    hasCpcPermission() {
      return this.hasPermission('cpc_campaigns');
    },
    hasCpaPermission() {
      return this.campaign.business.pixel_approved_at !== null;
    },
    allowedActiveCampaigns() {
      return this.getSetting('allowed_active_campaigns') || 2;
    },
    isCpcCampaign() {
      return this.campaign.cost_per_click > 0;
    },
    isCpaCampaign() {
      return this.campaign.cost_per_action_percent > 0 || this.campaign.cost_per_action_fixed > 0;
    },
    canPublish() {
      // Requires CPC permission for CPC campaigns
      if (this.isCpcCampaign && ! this.hasCpcPermission) {
        return false;
      }

      // Requires CPA permission for CPA campaigns
      if (this.isCpaCampaign && ! this.hasCpaPermission) {
        return false;
      }

      if (this.activeCampaigns) {
        return this.activeCampaigns.length < this.allowedActiveCampaigns;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("core/campaigns", {
      publishCampaignAction: "publishCampaign",
      loadCampaigns: "loadCampaigns"
    }),
    publishCampaign() {
      this.publishLoading = true;
      let params = {
        uuid: this.campaign.uuid
      };
      this.publishCampaignAction(params)
        .then(() => {
          this.setSnackSuccess("Succes");
          this.$router.push({
            name: "campaign",
            params: { id: this.campaign.uuid }
          });
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
          this.publishLoading = false;
        });
    },
    getActiveCampaigns() {
      this.activeCampaigns = null;
      let params = {
        active_campaigns: true,
        draft: false,
        page: 1,
        limit: 100
      };
      if (this.isAgency) {
        params.business_uuid = this.campaign.business.uuid;
      }
      this.loadCampaigns(params).then(campaigns => {
        this.activeCampaigns = campaigns.response;
      });
    }
  },
  created() {
    this.getActiveCampaigns();
  }
};
</script>
